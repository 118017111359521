<template>
  <div class="m-4">
    <h3 class="m-1 mt-5 mb-3">Asesmen Antenatal Care</h3>
    <v-row>
      <v-col cols="12" md="8" sm="8" lg="8">
        <v-card color="teal darken-1" dark>
          <v-card-text>
            <v-row class="mt-1 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-2 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :disabled="v.disabled ? v.disabled : false"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider class="mt-3 mb-3" />
        <v-row class="mt-0 mb-0">
          <v-col :md="6" :lg="6" :sm="12" cols="12">
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">STATUS OBSTETRI</div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form1"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <!-- {{ data }} -->
                <!-- {{ data.form1 }} -->

                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form1[i] = e)"
                  :value="data.form1[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">
              DATA KUNJUNGAN KEHAMILAN
            </div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form2"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <!-- {{ data.form2[i] }} -->

                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form2[i] = e)"
                  :value="data.form2[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">PELAYANAN KEHAMILAN</div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form3"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form3[i] = e)"
                  :value="data.form3[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col md="6" lg="6" sm="12" cols="12">
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">DATA TINDAKAN</div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form4"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form4[i] = e)"
                  :value="data.form4[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">
              DATA TEMA KONSELING / TEMU BICARA/ EDUKASI
            </div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form5"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form5[i] = e)"
                  :value="data.form5[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">
              PEMERIKSAAN PENUNJANG
            </div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form6"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form6[i] = e)"
                  :value="data.form6[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">PERESEPAN OBAT</div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form7"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form7[i] = e)"
                  :value="data.form7[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">PENGELUARAN OBAT</div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form8"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form8[i] = e)"
                  :value="data.form8[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">DIAGNOSA</div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form9"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form9[i] = e)"
                  :value="data.form9[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">
              KONDISI SAAT MENINGGAL FASKES
            </div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form10"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form10[i] = e)"
                  :value="data.form10[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">TINDAK LANJUT</div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form11"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form11[i] = e)"
                  :value="data.form11[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4" sm="4" lg="4">
        <v-card>
          <v-card-title> List Asesmen Antenatal </v-card-title>
          <v-card-text>
            <v-list three-line>
              <v-list-item v-for="(item, index) of listAsesmen" :key="index">
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-doctor </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.nama_poli }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    item.nama_dokter
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle
                    ><b>Tanggal Pemeriksaan</b> :
                    {{ item.tgl_pemeriksaan }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    ><b>Dibuat Oleh</b> :
                    {{ item.created_by }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    ><b class="primary--text">Dibuat Tanggal :</b>
                    {{ item.created_at }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    ><b class="primary--text">Diupdate Oleh :</b>
                    {{ item.updated_by }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    ><b class="primary--text">Diupdate Tanggal :</b>
                    {{ item.update_at }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    ><b class="primary--text">No. Registrasi :</b>
                    {{ item.no_reg }}</v-list-item-subtitle
                  >
                  <v-divider></v-divider>
                  <v-list-item-action>
                    <v-btn @click="viewhasil(item)" color="indigo" class="btn btn-block" medium dark
                      >Liat Hasil
                    </v-btn>
                  </v-list-item-action>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div class="py-2"></div>
    <a href="#test4" id="test4"></a>
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn @click="postSave" color="primary" large class="btn-block">
          Simpan Form
        </v-btn>
        &nbsp;
      </div>
    </div>
    <v-dialog v-model="showDialog" max-width="780">
      <v-card>
        <v-card-title class="headline"> Detail Antenatal </v-card-title>
        <v-card-text>
          {{ itemdetail }}
        </v-card-text>
      </v-card>
    </v-dialog>
    <dialog-confirm
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :positive-button="dialogActionPBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :disabled-positive-btn="dialogDisablePBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
    />
    <!-- <div v-html="jsonPrettier(data)"></div> -->
    <a href="#test3" id="test3"></a>
  </div>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../data/asesmen-antenatal-care'
import Dialog from '@/components/Dialog'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
export default {
  components: {
    DialogConfirm: Dialog,
    SmartWidget
  },
  data () {
    return {
      procedurs: [
        {
          previous: '',
          expiration: ''
        }
      ],
      pegawai: '',
      itemdetail: '',
      listAsesmen: '',
      showDialog: false,
      master: Master,
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        form1: {
          p1: {
            widget: 'wtext',
            data: '',
            col: 6,
            label: ' ',
            property: { attrs: { prefix: 'G : ', type: 'number' } }
          },
          p2: {
            widget: 'wtext',
            data: '',
            col: 6,
            label: ' ',
            property: { attrs: { prefix: 'P : ', type: 'number' } }
          },
          p3: {
            widget: 'wtext',
            data: '',
            col: 6,
            label: ' ',
            property: { attrs: { prefix: 'A : ', type: 'number' } }
          },
          p4: {
            widget: 'wtext',
            data: '',
            col: 6,
            label: ' ',
            property: { attrs: { prefix: 'H : ', type: 'number' } }
          },
          p5: {
            label: 'HPHT',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p6: {
            label: 'HPL',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p7: {
            label: 'Berat badan Sebelum Hamil',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p8: {
            label: 'Tinggi badan',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p9: {
            label: 'IMT sebelum hamil',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p10: {
            label: 'Target kenaikan BB',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p11: {
            label: 'Jarak kehamilan saat ini dengan kehamilan sebelumnya',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p12: {
            label: 'Status Imunisasi TT :',
            widget: 'wtext',
            data: [],
            col: 12
          }
        },
        form2: {
          p1: {
            label: 'Usia kehamilan (UK)',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p2: {
            label: 'Trimester ke',
            widget: 'wtext',
            data: [],
            col: 12
          }
        },
        form3: {
          l1: {
            label: 'A. Pemerikasaan Ibu:',
            widget: 'wlabelkiri',
            data: [],
            col: 12
          },
          p1: {
            label: 'Berat Badan ',
            widget: 'wtext',
            data: '',
            col: 12
          },
          p2: {
            label: 'Lingkar Lengan Atas (LILA)',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p3: {
            label: 'Tinggi Fundus ',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p4: {
            label: 'Tekanan darah diastolik ',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p5: {
            label: 'Nadi ',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p6: {
            label: 'Suhu ',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p7: {
            label: 'Pernafasan ',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p8: {
            label: 'Golongan darah ',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p9: {
            label: 'Rhesus ',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p10: {
            label: 'Pemberian makanan tambahan ibu hamil',
            widget: 'wradio',
            data: [
              { text: 'Ya', value: 'Ya' },
              { text: 'Tidak', value: 'Tidak' }
            ],
            col: 12
          },
          l2: {
            label: 'B. Pemerikasaan Fisik:',
            widget: 'wlabelkiri',
            data: [],
            col: 12
          },
          p11: {
            label: 'Konjuntiva : anemis',
            widget: 'wradio',
            data: [
              { text: 'Ya', value: 'Ya' },
              { text: 'Tidak', value: 'Tidak' }
            ],
            col: 12
          },
          p12: {
            label: 'Sklera : ikterik',
            widget: 'wradio',
            data: [
              { text: 'Ya', value: 'Ya' },
              { text: 'Tidak', value: 'Tidak' }
            ],
            col: 12
          },
          p12a: {
            label: 'Penjelasan (Sklera : ikterik)',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p13: {
            label: 'Leher',
            widget: 'wradio',
            data: [
              { text: 'Ya', value: 'Ya' },
              { text: 'Tidak', value: 'Tidak' }
            ],
            col: 12
          },
          p13a: {
            label: 'Penjelasan (Leher)',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p14: {
            label: 'Gigi dan mulut',
            widget: 'wradio',
            data: [
              { text: 'Ya', value: 'Ya' },
              { text: 'Tidak', value: 'Tidak' }
            ],
            col: 12
          },
          p14a: {
            label: 'Penjelasan (Gigi dan mulut)',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p15: {
            label: 'THT',
            widget: 'wradio',
            data: [
              { text: 'Ya', value: 'Ya' },
              { text: 'Tidak', value: 'Tidak' }
            ],
            col: 12
          },
          p15a: {
            label: 'Penjelasan (THT)',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p16: {
            label: 'Dada',
            widget: 'wradio',
            data: [
              { text: 'Ya', value: 'Ya' },
              { text: 'Tidak', value: 'Tidak' }
            ],
            col: 12
          },
          p16a: {
            label: 'Penjelasan (Dada)',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p17: {
            label: 'Perut',
            widget: 'wradio',
            data: [
              { text: 'Ya', value: 'Ya' },
              { text: 'Tidak', value: 'Tidak' }
            ],
            col: 12
          },
          p17a: {
            label: 'Penjelasan (Perut)',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p18: {
            label: 'Tungkai',
            widget: 'wradio',
            data: [
              { text: 'Ya', value: 'Ya' },
              { text: 'Tidak', value: 'Tidak' }
            ],
            col: 12
          },
          p18a: {
            label: 'Penjelasan (Tungkai)',
            widget: 'wtext',
            data: [],
            col: 12
          },
          l3: {
            label: 'C. Pemerikasaan Janin:',
            widget: 'wlabelkiri',
            data: [],
            col: 12
          },
          p19: {
            label: 'Denyut Jantung janin (DJJ)',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p20: {
            label: 'Kepala terhadap PAP',
            widget: 'wtext',
            data: [],
            col: 12,
            property: { attrs: { suffix: 'cm' } }
          },
          p21: {
            label: 'Tafsiran berat janin',
            widget: 'wtext',
            data: [],
            col: 12,
            property: { attrs: { suffix: 'gr' } }
          },
          p22: {
            label: 'Presentasi',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p23: {
            label: 'Jumlah janin',
            widget: 'wtext',
            data: [],
            col: 12
          },
          l4: {
            label: 'D. Pemantauan dan pendampingan',
            widget: 'wlabelkiri',
            data: [],
            col: 12
          },
          p24: {
            label: ' ',
            widget: 'wtext',
            data: [],
            col: 12
          },
          l5: {
            label: 'E. Riwayat penyakit & Resiko ',
            widget: 'wlabelkiri',
            data: [],
            col: 12
          },
          p25: {
            label: 'Komplikasi/penyulit kehamilan',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p26: {
            label: 'Riwayat penyakit menular ',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p27: {
            label: 'Riwayat Penyakit Tidak menular ',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p28: {
            label: 'Riwayat Penyakit Keluarga',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p29: {
            label: 'Merokok ',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p30: {
            label: 'Konsumsi Alkohol ',
            widget: 'wtext',
            data: [],
            col: 12
          },
          l6: {
            label: 'F. Kondisi Lainnya',
            widget: 'wlabelkiri',
            data: [],
            col: 12
          },
          p31: {
            label: ' ',
            widget: 'wtext',
            data: [],
            col: 12
          }
        },
        form4: {
          p1: {
            label: 'USG kehamilan',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p2: {
            label: 'BPD',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p3: {
            label: 'FL',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p4: {
            label: 'ACC',
            widget: 'wtext',
            data: [],
            col: 12
          }
        },
        form5: {
          p1: {
            label: 'Konseling',
            widget: 'wradio',
            data: [
              { text: 'Ya', value: 'Ya' },
              { text: 'Tidak', value: 'Tidak' }
            ],
            col: 12
          }
        },
        form6: {
          p1: {
            label: ' ',
            widget: 'wtext',
            data: [],
            col: 12
          }
        },
        form7: {
          p1: {
            label: ' ',
            widget: 'wtext',
            data: [],
            col: 12
          }
        },
        form8: {
          p1: {
            label: ' ',
            widget: 'wtext',
            data: [],
            col: 12
          }
        },
        form9: {
          p1: {
            label: 'Diagnosa Utama',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p2: {
            label: 'Diagnosa Sekunder',
            widget: 'wtext',
            data: [],
            col: 12
          }
        },
        form10: {
          p1: {
            label: ' ',
            widget: 'wtext',
            data: [],
            col: 12
          }
        },
        form11: {
          p1: {
            label: 'Pulang, kontrol kembali tanggal:',
            widget: 'wdatenormal',
            data: [],
            col: 12
          },
          p2: {
            label: 'Di rujuk ke:',
            widget: 'wtext',
            data: [],
            col: 12
          },
          p3: {
            label: 'Kembali ke faskes:',
            widget: 'wtext',
            data: [],
            col: 12
          }
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.getAssemen(this.$route.query.folio_id)
      this.getListPasienAsesmen()
      this.pegawai = JSON.parse(localStorage.getItem('user'))
    }
  },
  methods: {
    postSave () {
      // const formData = new FormData()
      // formData.append(
      //   'data',
      //   new Blob([JSON.stringify(this.data)], { type: 'application/json' })
      // )
      // console.log(JSON.parse(JSON.stringify(this.data)))

      // console.log(this.data)
      // eslint-disable-next-line no-unused-vars
      var data = {
        rm: this.$route.query.no_rm,
        created_by: this.pegawai.employee_id,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        data: this.data,
        type: 'Asesmen Antenal Care',
        jenis: 'rawat-jalan'
      }

      console.log(data)
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-asesmen-rawat-inap',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    addPro () {
      this.data.procedurs.push({
        previous: '',
        expiration: ''
      })
    },
    deletePro (counter) {
      this.data.procedurs.splice(counter, 1)
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },
    getAssemen (folioId) {
      var type = 'Asesmen Antenal Care'
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-asesmen-rawat-inap',
        { folio_id: folioId, jenis: type },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data = results
            // this.data.form6 = results.form6
            // this.data.demografi.jenis_kelamin = {
            //   value: results.jenis_kelamin,
            //   text: results.jenis_kelamin
            // }
          } else {
          }
        },
        'JSON'
      )
    },
    getListPasienAsesmen () {
      // eslint-disable-next-line no-unused-vars, camelcase
      var no_rm = this.$route.query.no_rm
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-asesmen-anatal',
        { no_rm: no_rm },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.listAsesmen = results
            // this.data.form6 = results.form6
            // this.data.demografi.jenis_kelamin = {
            //   value: results.jenis_kelamin,
            //   text: results.jenis_kelamin
            // }
          } else {
            this.listAsesmen = []
          }
        },
        'JSON'
      )
    },
    viewhasil (item) {
      this.showDialog = true
      this.itemdetail = JSON.parse(item.pemeriksaan)
    }
  }
}
</script>

<style>
.float-group {
  position: fixed;
  bottom: 10%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

</style>
