export default {
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdate',
      data: null,
      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      label: 'Dokter',
      // data: [
      //   { text: 'Laki-Laki', value: 'laki-laki' },
      //   { text: 'Wanita', value: 'wanita' }
      // ],
      data: null,
      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Jenis Rawatan',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 4
    }
  },
  form1: {
    p1: {
      widget: 'wtext',
      data: '',
      col: 6,
      label: ' ',
      property: { attrs: { prefix: 'G : ', type: 'number' } }
    },
    p2: {
      widget: 'wtext',
      data: '',
      col: 6,
      label: ' ',
      property: { attrs: { prefix: 'P : ', type: 'number' } }
    },
    p3: {
      widget: 'wtext',
      data: '',
      col: 6,
      label: ' ',
      property: { attrs: { prefix: 'A : ', type: 'number' } }
    },
    p4: {
      widget: 'wtext',
      data: '',
      col: 6,
      label: ' ',
      property: { attrs: { prefix: 'H : ', type: 'number' } }
    },
    p5: {
      label: 'HPHT',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p6: {
      label: 'HPL',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p7: {
      label: 'Berat badan Sebelum Hamil',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p8: {
      label: 'Tinggi badan',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p9: {
      label: 'IMT sebelum hamil',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p10: {
      label: 'Target kenaikan BB',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p11: {
      label: 'Jarak kehamilan saat ini dengan kehamilan sebelumnya',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p12: {
      label: 'Status Imunisasi TT :',
      widget: 'wtext',
      data: [],
      col: 12
    }
  },
  form2: {
    p1: {
      label: 'Usia kehamilan (UK)',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p2: {
      label: 'Trimester ke',
      widget: 'wtext',
      data: [],
      col: 12
    }
  },
  form3: {
    l1: {
      label: 'A. Pemerikasaan Ibu:',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    p1: {
      label: 'Berat Badan ',
      widget: 'wtext',
      data: '',
      col: 12
    },
    p2: {
      label: 'Lingkar Lengan Atas (LILA)',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p3: {
      label: 'Tinggi Fundus ',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p4: {
      label: 'Tekanan darah diastolik ',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p5: {
      label: 'Nadi ',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p6: {
      label: 'Suhu ',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p7: {
      label: 'Pernafasan ',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p8: {
      label: 'Golongan darah ',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p9: {
      label: 'Rhesus ',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p10: {
      label: 'Pemberian makanan tambahan ibu hamil',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    },
    l2: {
      label: 'B. Pemerikasaan Fisik:',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    p11: {
      label: 'Konjuntiva : anemis',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    },
    p12: {
      label: 'Sklera : ikterik',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    },
    p12a: {
      label: 'Penjelasan (Sklera : ikterik)',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p13: {
      label: 'Leher',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    },
    p13a: {
      label: 'Penjelasan (Leher)',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p14: {
      label: 'Gigi dan mulut',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    },
    p14a: {
      label: 'Penjelasan (Gigi dan mulut)',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p15: {
      label: 'THT',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    },
    p15a: {
      label: 'Penjelasan (THT)',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p16: {
      label: 'Dada',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    },
    p16a: {
      label: 'Penjelasan (Dada)',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p17: {
      label: 'Perut',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    },
    p17a: {
      label: 'Penjelasan (Perut)',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p18: {
      label: 'Tungkai',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    },
    p18a: {
      label: 'Penjelasan (Tungkai)',
      widget: 'wtext',
      data: [],
      col: 12
    },
    l3: {
      label: 'C. Pemerikasaan Janin:',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    p19: {
      label: 'Denyut Jantung janin (DJJ)',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p20: {
      label: 'Kepala terhadap PAP',
      widget: 'wtext',
      data: [],
      col: 12,
      property: { attrs: { suffix: 'cm' } }
    },
    p21: {
      label: 'Tafsiran berat janin',
      widget: 'wtext',
      data: [],
      col: 12,
      property: { attrs: { suffix: 'gr' } }
    },
    p22: {
      label: 'Presentasi',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p23: {
      label: 'Jumlah janin',
      widget: 'wtext',
      data: [],
      col: 12
    },
    l4: {
      label: 'D. Pemantauan dan pendampingan',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    p24: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 12
    },
    l5: {
      label: 'E. Riwayat penyakit & Resiko ',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    p25: {
      label: 'Komplikasi/penyulit kehamilan',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p26: {
      label: 'Riwayat penyakit menular ',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p27: {
      label: 'Riwayat Penyakit Tidak menular ',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p28: {
      label: 'Riwayat Penyakit Keluarga',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p29: {
      label: 'Merokok ',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p30: {
      label: 'Konsumsi Alkohol ',
      widget: 'wtext',
      data: [],
      col: 12
    },
    l6: {
      label: 'F. Kondisi Lainnya',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    p31: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 12
    }
  },
  form4: {
    p1: {
      label: 'USG kehamilan',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p2: {
      label: 'BPD',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p3: {
      label: 'FL',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p4: {
      label: 'ACC',
      widget: 'wtext',
      data: [],
      col: 12
    }
  },
  form5: {
    p1: {
      label: 'Konseling',
      widget: 'wradio',
      data: [
        { text: 'Ya', value: 'Ya' },
        { text: 'Tidak', value: 'Tidak' }
      ],
      col: 12
    }
  },
  form6: {
    p1: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 12
    }
  },
  form7: {
    p1: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 12
    }
  },
  form8: {
    p1: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 12
    }
  },
  form9: {
    p1: {
      label: 'Diagnosa Utama',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p2: {
      label: 'Diagnosa Sekunder',
      widget: 'wtext',
      data: [],
      col: 12
    }
  },
  form10: {
    p1: {
      label: ' ',
      widget: 'wtext',
      data: [],
      col: 12
    }
  },
  form11: {
    p1: {
      label: 'Pulang, kontrol kembali tanggal:',
      widget: 'wdatenormal',
      data: [],
      col: 12
    },
    p2: {
      label: 'Di rujuk ke:',
      widget: 'wtext',
      data: [],
      col: 12
    },
    p3: {
      label: 'Kembali ke faskes:',
      widget: 'wtext',
      data: [],
      col: 12
    }
  }
}
